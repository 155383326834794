<template>
  <v-container
    id="data-tables"
    tag="section"
  >
    <base-material-card
      color="indigo"
      icon="mdi-play-box-multiple-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Manage Video Series
        </div>
      </template>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="videos"
        :disable-pagination="true"
        hide-default-footer
        sort-by="seriesID"
        item-key="seriesID"
        sort-desc
      >
        <template v-slot:top>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="searchTerm"
                  append-icon="mdi-magnify"
                  class="ml-auto"
                  label="Search..."
                  hide-details
                  single-line
                  clearable
                  @change="filterTable"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  class="mx-auto"
                  v-model="searchCat"
                  :items="categoryOptions"
                  item-text="tagName"
                  item-value="id"
                  label="Select a category."
                  clearable
                  v-on:change="filterTable"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  :items="pageOptions"
                  v-model="rowsPerPage"
                  append-icon="mdi-chevron-down"
                  class="ml-auto"
                  label="Rows per Page"
                  hide-details
                  v-on:change="filterTable"
                >
                </v-select>
              </v-col>
               <v-col cols="12" sm="6" md="2" class="text-sm-center">
                <v-dialog v-model="addSeries" :retain-focus="false" persistent max-width="600px" scrollable>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="mt-4 mr-0"
                    >
                      Add Series
                    </v-btn>
                  </template>
                  <v-card :loading="cardLoading">
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="seriesName" label="Series Title*" :rules="seriesNameRules" required></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" v-on:click.stop="addSeries = false">Nevermind</v-btn>
                      <v-btn :disabled="!seriesName" color="green darken-1" v-on:click.stop="createSeries">Create</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
               </v-col>
            </v-row>
            <v-spacer></v-spacer>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a target="_blank" :href="item.publishLink" style="text-decoration: none">
                <v-icon :disabled="!item.publishLink" small class="mr-2" v-bind="attrs" v-on="on" color="indigo lighten-1">
                  mdi-eye
                </v-icon>
              </a>
            </template>
            <span>View</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-3"
                @click="viewSeries(item)"
                v-bind="attrs"
                v-on="on"
                color="teal lighten-1"
              >
              mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-on:click.stop="deleteSeriesConfirm(item)"
                v-bind="attrs"
                v-on="on"
                color="error"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
        <template v-slot:item.published="{ item }">
          <v-tooltip v-if="item.published !== null" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="success"
              >
              mdi-check
              </v-icon>
            </template>
            <span>Published</span>
           </v-tooltip>
           <v-tooltip v-if="item.published === null" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="error"
              >
              mdi-close
              </v-icon>
            </template>
            <span>Not Published</span>
           </v-tooltip>
        </template>
        <template v-slot:no-data>
          <h1>Sorry, we couldn't find anything that matched that search.</h1>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || loading === true" color="blue darken-1" v-on:click="previousPage()"><v-icon >mdi-chevron-left</v-icon></v-btn>
        <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || loading === true" color="blue darken-1" v-on:click="nextPage()"><v-icon >mdi-chevron-right</v-icon></v-btn>
      </div>
      <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
        <v-progress-circular
          v-if="snackColor === 'info'"
          color="white"
          indeterminate
        ></v-progress-circular>
        {{ snackText }}
      </v-snackbar>
      <v-dialog
        :retain-focus="false"
        v-model="deleteConfirmDialog"
        max-width="500"
      >
        <v-card :loading="confirmLoading">
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text>
            This series will be deleted, and unpublished. <br> It can be recovered later by contacting a Frog Administrator
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              v-on:click.stop="deleteConfirmDialog = false"
            >
              No, don't delete
            </v-btn>
            <v-btn
              color="red darken-1"
              v-on:click.stop="deleteItem"
            >
            Yes, delete video series
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </base-material-card>
  </v-container>
</template>

<script>
import videoManagement from '@/Services/Videos/VideoManagement'
import createSeries from '@/Services/Videos/CreateSeries'
import deleteSeries from '@/Services/Videos/DeleteSeries'
export default {
  data: () => ({
    snack: false,
    snackColor: '',
    snackText: '',
    snackTimeout: null,
    searchURL: null,
    nextCursor: null,
    prevCursor: null,
    rowsPerPage: 10,
    pageOptions: [5, 10, 20, 50, 100],
    categoryOptions: [
      {
        text: 'Please Select',
        value: null
      }
    ],
    loading: true,
    cardLoading: false,
    confirmLoading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'seriesID'
      },
      { text: 'Name', value: 'name' },
      { text: 'Video Count', value: 'videoCount' },
      { text: 'Actions', value: 'actions', sortable: false },
      { text: 'Published/Unpublished', value: 'published' }
    ],
    videos: [],
    errors: [],
    searchTerm: null,
    searchCat: null,
    addSeries: false,
    deleteConfirmDialog: false,
    seriesName: '',
    seriesNameRules: [
      v => !!v || 'Series title is required'
    ],
    selectedSeries: null,
    editedIndex: -1,
    editedItem: {
      name: '',
      category: null,
      summary: '',
      gender: null,
      age: '',
      viewpoint: null
    },
    defaultItem: {
      name: '',
      category: null,
      summary: '',
      gender: null,
      age: '',
      viewpoint: null
    },
    deleteSeriesID: null
  }),
  created () {
    this.initialize()
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Video Series' : 'Edit Video Series'
    }
  },

  watch: {
    addSeries (val) {
      val || this.close()
    }
  },
  methods: {
    async initialize () {
      this.loading = true
      await videoManagement.init(this.rowsPerPage)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'videos', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load videos.'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e)
        })
      await videoManagement.getCat()
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'categoryOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load categories.'
            this.snackTimeout = 3000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    async previousPage () {
      this.loading = true
      await videoManagement.getPreviousPage(this.rowsPerPage, this.searchURL, this.prevCursor)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'videos', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get previous results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async nextPage () {
      this.loading = true
      await videoManagement.getNextPage(this.rowsPerPage, this.searchURL, this.nextCursor)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'videos', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get next results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async createSeries () {
      this.cardLoading = true
      await createSeries.createSeries(this.seriesName)
        .then((response) => {
          if (response.status === 200) {
            this.cardLoading = false
            this.addSeries = false
            this.initialize()
            const editUrl = '/manage/series/' + response.data.seriesID
            this.$router.push(editUrl)
          } else if (response.status !== 200) {
            this.cardLoading = false
            this.addSeries = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to create series. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
    },
    viewSeries (item) {
      const editUrl = '/manage/series/' + item.seriesID
      this.$router.push(editUrl)
    },
    deleteSeriesConfirm (item) {
      this.deleteSeriesID = item.seriesID
      this.deleteConfirmDialog = true
    },
    async deleteItem () {
      this.confirmLoading = true
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Deleting...'
      this.snackTimeout = -1
      await deleteSeries.deleteSeries(this.deleteSeriesID)
        .then((response) => {
          if (response.status === 200) {
            this.confirmLoading = false
            this.deleteConfirmDialog = false
            this.initialize()
            this.snackColor = 'success'
            this.snackText = 'Series deleted successfully!'
            this.snackTimeout = 3000
          } else {
            this.confirmLoading = false
            this.deleteConfirmDialog = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to create series. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    publishItem (item) {},
    close () {
      this.addSeries = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.videos[this.editedIndex], this.editedItem)
      } else {
        this.videos.push(this.editedItem)
      }
      this.close()
    },
    async filterTable () {
      this.loading = true
      const term = this.searchTerm
      const cat = this.searchCat
      let url = 'articles/videos/admin?count=' + this.rowsPerPage
      let extraParam = null
      if (term && cat) {
        extraParam = '&term=' + term + '&cat=' + cat
      } else if (term && !cat) {
        extraParam = '&term=' + term
      } else if (!term && cat) {
        extraParam = '&cat=' + cat
      }
      if (extraParam !== null) {
        url = url + extraParam
      }
      if (extraParam !== null) {
        this.searchURL = '?count=' + this.rowsPerPage + extraParam
      } else if (extraParam === null) {
        this.searchURL = '?count=' + this.rowsPerPage
      }
      await videoManagement.filterTable(url)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'videos', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to retrieve results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.$set(this, 'videos', [])
          this.loading = false
        })
    }
  }
}
</script>
